import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/user.context";
import { signOutUser } from "../firebase";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(0);
  const { currentUser, setCurrentUser } = useContext(UserContext);

  const signOutHandler = async () => {
    const res = await signOutUser();
    setCurrentUser(null);
  };

  return (
    <Box sx={{ width: window.width }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label="Home"
          icon={<HomeIcon />}
          component={Link}
          to="/search"
        />
        <BottomNavigationAction
          label="Contact Us"
          icon={<EmailIcon />}
          component={Link}
          to="/contactus"
        />
        {currentUser ? (
          <BottomNavigationAction
            label="Manage"
            icon={<ManageAccountsIcon />}
            component={Link}
            to="/Manage"
          />
        ) : (
          <BottomNavigationAction
            label="Login"
            icon={<LoginIcon />}
            component={Link}
            to="/login"
          />
        )}
        {currentUser ? (
          <BottomNavigationAction
            label="Logout"
            icon={<LogoutIcon />}
            component={Link}
            onClick={signOutHandler}
            to="/login"
          />
        ) : (
          <BottomNavigationAction
            label="Register"
            icon={<FollowTheSignsIcon />}
            component={Link}
            to="/register"
          />
        )}
      </BottomNavigation>
    </Box>
  );
}
