import { colors, createTheme } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import { red } from "@mui/material/colors";
import { Typography } from "@mui/material/styles/createTypography";

const theme = createTheme({
  /* breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
    },
  },*/

  palette: {
    type: "dark",
    primary: {
      main: "#49536F",
    },
    secondary: {
      main: "#B7ABA6",
      contrastText: "#ffebee",
    },

    background: {
      default: "#395159",
      //paper: "#FFD600",
    },
  },

  typography: {
    fontFamily: "Merriweather",
  },
});

export default theme;
