import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createAuthUserWithEmailAndPassword } from "../firebase";
import { createUserDocumentFromAuth } from "../functions/users";
import Button from "@mui/material/Button";
import { UserContext } from "../contexts/user.context";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import { Card, CardContent, TextField, useMediaQuery } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Vector from "../images/Vector.png";
import { ToastContainer, toast } from "react-toastify";

const initialState = {
  displayName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const Register = () => {
  const [values, setValue] = useState(initialState);
  const [passwordError, setPasswordError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const { displayName, email, password, confirmPassword } = values;

  const { currentUser, setCurrentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const resetFormFields = () => {
    setValue(initialState);
  };

  useEffect(() => {
    if (currentUser) {
      navigate("/dataInput");
    }
  }, [currentUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisableButton(true);

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    toast.success("Please Wait, signing you up!");

    try {
      const { user } = await createAuthUserWithEmailAndPassword(
        email,
        password
      );
      toast.success("Successfully Signed Up!");
      await createUserDocumentFromAuth(user, { displayName });
      setCurrentUser(user);
      resetFormFields();
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        toast.error("Email already in use");
      } else {
        console.log(error);
        toast.error("Registration Failed");
      }
      setDisableButton(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      if (value.length < 6) {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }
    }
    setValue({ ...values, [name]: value });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <Grid
          item
          container
          justifyContent="center"
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Card
            elevation={5}
            sx={{
              minWidth: 300,

              //maxWidth: 1600,
              minHeight: 180,
              backgroundColor: "#ECECEC",
              //margin: 2,
              //justify: "center",
            }}
          >
            <CardContent justify="center">
              <h1 style={{ fontSize: isMobile ? "1rem" : "1.5rem" }}>
                Let's Connect You with More Customers!
              </h1>
              <form onSubmit={handleSubmit}>
                <Box
                  //component="form"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                  alignItems="center"
                  justifyContent="center"
                  noValidate
                  autoComplete="off"
                >
                  <Stack
                    spacing={{ xs: 1, sm: 2, md: 3, lg: 6 }}
                    direction={{
                      xs: "column",
                      sm: "column",
                      md: "column",
                      lg: "row",
                    }}
                  >
                    <TextField
                      id="outline-required"
                      label="Display Name"
                      type="text"
                      required
                      onChange={handleChange}
                      name="displayName"
                      value={displayName}
                    />

                    <TextField
                      type="email"
                      required
                      label="Email"
                      onChange={handleChange}
                      name="email"
                      value={email}
                    />
                  </Stack>

                  <Stack
                    spacing={{ xs: 1, sm: 2, md: 3, lg: 6 }}
                    direction={{
                      xs: "column",
                      sm: "column",
                      md: "column",
                      lg: "row",
                    }}
                  >
                    <TextField
                      type="password"
                      required
                      label="Password"
                      onChange={handleChange}
                      name="password"
                      value={password}
                      error={passwordError}
                    />

                    <TextField
                      type="password"
                      required
                      label="Confirm Password"
                      onChange={handleChange}
                      name="confirmPassword"
                      value={confirmPassword}
                    />
                  </Stack>
                  <br />
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={disableButton}
                  >
                    Sign Up
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </div>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default Register;
