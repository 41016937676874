//import axios from 'axios'
import {
  collection,
  addDoc,
  where,
  updateDoc,
  getDoc,
  query,
  getDocs,
  doc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../config/firestore";

const busRef = collection(db, "businesses");
const searchRef = collection(db, "searches");

export const getAllBusiness = async (data) => {
  let result = [];
  const q = query(collection(db, "businesses"));
  const querySnapshot = await getDocs(q);
  //console.log(querySnapshot.docs)
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    const structure = {
      id: doc.id,
      data: doc.data(),
    };
    result.push(structure);
    //console.log(doc.id, " => ", doc.data());
  });
  return result;
  //await axios.get(`${process.env.REACT_APP_API}business`);
};

export const getBusiness = async (id) => {
  const docSnap = await getDoc(doc(db, "businesses", id));

  if (docSnap.exists()) {
    //console.log(docSnap.id);
    //console.log(docSnap.data());
    return docSnap.data();
  }
};

export const getUserBusinesses = async (uid) => {
  console.log(uid);
  let result = [];
  const q = query(collection(db, "businesses"));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.data().uid);
    if (uid === doc.data().uid) {
      const structure = {
        id: doc.id,
        data: doc.data(),
      };
      result.push(structure);
    }

    //console.log(doc.id, " => ", doc.data());
  });

  /*
  const q = query(collection(db, "businesses")).where("uid", "==", uid);
  const querySnapshot = await getDocs(q);
  //console.log(querySnapshot);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    
    const structure = {
      id: doc.id,
      data: doc.data(),
    };
   
    result.push(doc.data);
    //console.log(doc.id, " => ", doc.data());
    
  }
  );
*/
  console.log("Just before sending result in getUserBusiness function call");
  return result;
  //const docSnap = await getDoc(doc(db, "businesses", id));
  /*
  if (querySnap.exists()) {
    //console.log(docSnap.id);
    //console.log(docSnap.data());
    return querySnap.data();
  }
  */
};

export const createBusiness = async (business) => {
  //console.log(business);
  try {
    //const newBusinessRef = await doc(collection(db, "businesses"));
    addDoc(busRef, {
      name: business.name,
      street: business.street,
      city: business.city,
      state: business.state,
      zipCode: business.zipCode,
      phone: business.phone,
      email: business.email,
      notes: business.notes,
      experience: business.experience,
      certifications: business.certifications,
      website: business.website,
      twitter: business.twitter,
      instagram: business.instagram,
      linkedIn: business.linkedIn,
      facebook: business.facebook,
      acceptingClients: business.acceptingClients,
      byApptOnly: business.byApptOnly,
      active: business.active,
      contactName: business.contactName,
      locations: business.locations,
      services: business.services,
      hours: business.hours,
      editableHours: business.editableHours,
      rank: 5,
      uid: business.uid,
      sign_up_date: Date.now(),
    }).then(() => {
      console.log("Business has been created");
    });
    //console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};
//await axios.post(`${process.env.REACT_APP_API}business`, data)

export const updateBusiness = async (id, business) => {
  try {
    const docRef = await updateDoc(doc(db, "businesses", id), {
      name: business.name,
      street: business.street,
      city: business.city,
      state: business.state,
      zipCode: business.zipCode,
      phone: business.phone,
      email: business.email,
      notes: business.notes,
      experience: business.experience,
      certifications: business.certifications,
      website: business.website,
      twitter: business.twitter,
      instagram: business.instagram,
      linkedIn: business.linkedIn,
      facebook: business.facebook,
      acceptingClients: business.acceptingClients,
      byApptOnly: business.byApptOnly,
      active: business.active,
      contactName: business.contactName,
      locations: business.locations,
      services: business.services,
      hours: business.hours,
      editableHours: business.editableHours,
      uid: business.uid,
    });
    //console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const getAllServices = async () => {
  let result = [];
  const q = query(collection(db, "services"));
  const querySnapshot = await getDocs(q);
  //console.log(querySnapshot.docs)
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    /*
    const structure = {
      id: doc.id,
      data: doc.data()
    }
    */
    //console.log(doc.data());
    result.push(doc.data());
    //console.log(doc.id, " => ", doc.data());
  });
  //console.log(result)
  return result;
};

export const getAllLocations = async () => {
  let result = [];
  const q = query(collection(db, "locations"));
  const querySnapshot = await getDocs(q);
  //console.log(querySnapshot.docs)
  querySnapshot.forEach((doc) => {
    result.push(doc.data());
    //console.log(doc.id, " => ", doc.data());
  });
  //console.log(result)
  return result;
};

export const addSearch = async (search) => {
  try {
    addDoc(searchRef, {
      service: search.service,
      location: search.location,
      created: Timestamp.now(),
    }).then(() => {
      console.log("Search has been added");
    });
    //console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding search: ", e);
  }
};

export const searchBusinesses = async (service) => {
  let results = [];
  //console.log(service)
  try {
    let q;

    if (service === ".Any") {
      q = query(busRef);
    } else {
      q = query(
        busRef,
        where("services", "array-contains", service),
        where("active", "==", true)
      );
    }

    const querySnapshot = await getDocs(q);
    //console.log(querySnapshot.docs)
    querySnapshot.forEach((doc) => {
      results.push(doc.data());
      //console.log(doc.id, " => ", doc.data());
    });

    //const t = query(busRef, where("locations", "array-contains", location));
    //const querySnapshot1 = await getDocs(t);
    //console.log(querySnapshot.docs)
    //querySnapshot1.forEach((doc) => {
    //   locations.push(doc.data())
    //console.log(doc.id, " => ", doc.data());
    //})
    //console.log(locations[0].services)

    //services.forEach((service) => {
    //  locations.forEach((location) => {
    //    if(service.locations == location.locations){
    //      results.push(service)
    //    }
    //  })
    //})

    //const businessQuery = query(busRef, where("services", "array-contains", service))

    //getDocs(businessQuery, (docs) => {
    //  console.log(docs)
    //  docs.forEach((doc) => {
    //    result.push(doc.data())
    //  })
    //});

    //console.log(services);
    //console.log(locations);
    return results;
  } catch (e) {
    console.error("Error retrieving search results: ", e);
  }
};
