import React, { useEffect, useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { getUserBusinesses } from "../functions/business";
import { UserContext } from "../contexts/user.context";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, CssBaseline, Stack } from "@mui/material";
//import Grid from '@mui/material/Grid';
import Nav from "../components/nav";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import { fontFamily } from "@mui/system";
import { Card } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  ":root": {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const BusinessManager = () => {
  const { currentUser } = useContext(UserContext);
  const [businessList, setBusinessList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getUserBusinesses(currentUser.uid).then((data) => {
      setBusinessList(data);
      console.log(data);
      console.log("Inside Business Manager after getting user businesses");
    });
    /*
    const userBusinesses = getUserBusinesses(currentUser.uid);
    console.log(userBusinesses);
    setBusinessList(userBusinesses);
    */
  }, [currentUser]);

  const logBusiness = () => {
    console.log(businessList);
  };

  const handleClick = (e, id) => {
    navigate(`/business/${id}`);
  };

  const BusinessTable = () => (
    <ThemeProvider>
      <CssBaseline />
      <TableContainer component={Paper}>
        <Table
          sx={{ width: "auto", color: "blue" }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Locations Serviced</StyledTableCell>
              <StyledTableCell>Services</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {businessList &&
              businessList.map((b) => (
                <StyledTableRow
                  key={b.data.id}
                  onClick={(e) => handleClick(e, b.id)}
                >
                  <StyledTableCell align="left">{b.data.name}</StyledTableCell>
                  <StyledTableCell align="left">
                    {b.data.locations &&
                      b.data.locations.map((l, i) => {
                        return <p key={i}>{l}</p>;
                      })}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {b.data.services &&
                      b.data.services.map((s, i) => {
                        return <p key={i}>{s}</p>;
                      })}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Stack direction="column" alignItems="center">
          <h1
            style={{
              fontSize: "3em",
              fontFamily: "Merriweather",
              lineHeight: "normal",
            }}
          >
            Business Manager
          </h1>
          <Button
            style={{
              color: "black",
              fontWeight: "bold",
              alignItem: "center",
              width: "200px",
            }}
            variant="contained"
            color="secondary"
            component={Link}
            to="/datainput"
          >
            Create New
          </Button>
          <br />
          <Card
            elevation={5}
            sx={{
              backgroundColor: "#ECECEC",
              margin: 2,
            }}
          >
            <BusinessTable />
          </Card>
        </Stack>
      </ThemeProvider>
    </>
  );
};

export default BusinessManager;
