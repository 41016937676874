import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/user.context";
import { signOutUser } from "../firebase";
import Box from "@mui/material/Box";
import { useTheme, useMediaQuery } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
//import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
//import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from "@mui/material/ListItemText";
//import InboxIcon from '@mui/icons-material/MoveToInbox';
//import MailIcon from '@mui/icons-material/Mail';
import { useState } from "react";

const drawerWidth = 240;

export default function PermanentDrawerLeft() {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [openDrawer, setOpenDrawer] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const signOutHandler = async () => {
    const res = await signOutUser();
    setCurrentUser(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="sticky"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        {!isMobile ? (
          <Drawer
            sx={{
              width: drawerWidth,

              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: 180,
                boxSizing: "border-box",
                backgroundColor: "#ECECEC",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Toolbar />
            {currentUser ? <h3>{currentUser.displayName}</h3> : <></>}
            <Divider />

            <List>
              <ListItem button component={Link} to="/search" key={1}>
                <ListItemText primary="Home" />
              </ListItem>
              {currentUser ? (
                <ListItem
                  button
                  onClick={signOutHandler}
                  component={Link}
                  to="/login"
                  key={4}
                >
                  <ListItemText primary="Sign Out" />
                </ListItem>
              ) : (
                <>
                  <ListItem button component={Link} to="/login" key={5}>
                    <ListItemText primary="Sign In" />
                  </ListItem>
                  <ListItem button component={Link} to="/register" key={9}>
                    <ListItemText primary="Register" />
                  </ListItem>
                </>
              )}
              <ListItem button component={Link} to="/contactus" key={8}>
                <ListItemText primary="Contact Us" />
              </ListItem>
              <Divider />
              {currentUser ? (
                <List>
                  <ListItem button component={Link} to="/Manage" key={5}>
                    <ListItemText primary="Manage Businesses" />
                  </ListItem>
                  <divider />
                </List>
              ) : (
                <></>
              )}
            </List>
            <Divider />
          </Drawer>
        ) : (
          <></>
        )}
      </AppBar>
    </Box>
  );
}
