import { useState } from "react";
import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";
import Business from "./pages/business";
import EditBusiness from "./pages/editBusiness";
import ServiceList from "./pages/servicelist";
import DataInput from "./pages/dataInput";
import ListBusiness from "./pages/listBusiness";
import Home from "./pages/home";
import ContactUs from "./pages/contactus";
import Login from "./pages/login";
import Register from "./pages/register";
import BusinessManager from "./pages/businessManager";
import Nav from "./components/nav";
import BottomNav from "./components/bottomNav";
import Header from "./components/header";
import Toolbar from "@mui/material/Toolbar";
import { Stack } from "@mui/material";

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="App">
      <div className="container-fluid">
        <div className="row flex-nowrap">
          <Nav />
          <div style={{ marginLeft: isMobile ? 0 : 200 }}>
            <Header />
            <Routes>
              <Route path="/" element={<Business />} />
              <Route path="/home" element={<Business />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/search" element={<Business />} />
              <Route path="/Manage" element={<BusinessManager />} />
              <Route path="/datainput" element={<DataInput />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/business/:id" element={<EditBusiness />} />
            </Routes>
          </div>
          <br />
          {isMobile ? <BottomNav /> : <></>}
        </div>
      </div>
    </div>
  );
}

export default App;
