import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllBusiness } from "../functions/business";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/material";
//import Grid from '@mui/material/Grid';
import Nav from "../components/nav";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import CssBaseline from "@mui/material/CssBaseline";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  ":root": {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ListBusiness = () => {
  const [businesses, setBusinesses] = useState([]);
  const [mounted, setMounted] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    getAllBusiness().then((b) => {
      console.log(b);
      if (isMounted) setBusinesses(b);
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const handleClick = (e, id) => {
    navigate(`/business/${id}`);
  };

  const BusinessTable = () => (
    <ThemeProvider>
      <CssBaseline />
      <TableContainer component={Paper}>
        <Table sx={{ width: "auto" }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Active</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Contact</StyledTableCell>
              <StyledTableCell>Phone</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>

              <StyledTableCell>Locations Serviced</StyledTableCell>
              <StyledTableCell>Services</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {businesses.map((b) => (
              <StyledTableRow
                key={b.id}
                onClick={(e) => handleClick(e, b.id)}
                style={{ cursor: "pointer" }}
              >
                <StyledTableCell align="left">{b.data.active}</StyledTableCell>
                <StyledTableCell align="left">{b.data.name}</StyledTableCell>
                <StyledTableCell align="left">
                  {b.data.contactName}
                </StyledTableCell>

                <StyledTableCell align="left">{b.data.phone}</StyledTableCell>
                <StyledTableCell align="left">{b.data.email}</StyledTableCell>

                <StyledTableCell align="left">
                  {b.data.locations &&
                    b.data.locations.map((l, i) => {
                      return <p key={i}>{l}</p>;
                    })}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {b.data.services &&
                    b.data.services.map((s, i) => {
                      return <p key={i}>{s}</p>;
                    })}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <BusinessTable />
      </ThemeProvider>
    </>
  );
};

export default ListBusiness;
