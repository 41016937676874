import React, { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
//import { createLocation } from "../functions/locations";
//import { createService } from "../functions/services";
import {
  createBusiness,
  getBusiness,
  getAllLocations,
  getAllServices,
} from "../functions/business";
import { UserContext } from "../contexts/user.context";
import "react-toastify/dist/ReactToastify.css";
import Nav from "../components/nav";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import MuiPhoneNumber from "material-ui-phone-number";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { fontSize } from "@mui/system";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#ECECEC" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DataInput = () => {
  //const [location, setLocation] = useState('');
  //const [service, setService] = useState('');
  const { currentUser } = useContext(UserContext);
  const [name, setName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [notes, setNotes] = useState("");
  const [experience, setExperience] = useState(0);
  const [certifications, setCertifications] = useState("");
  const [website, setWebsite] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [facebook, setFacebook] = useState("");
  const [contactName, setContactName] = useState("");
  const [acceptingClients, setAcceptingClients] = useState("Yes");
  const [byApptOnly, setByApptOnly] = useState("No");
  const [mondayOpenDate, setMondayOpenDate] = useState(new Date());
  const [mondayCloseDate, setMondayCloseDate] = useState(new Date());
  const [mondayOpenPeriod, setMondayOpenPeriod] = useState("AM");
  const [mondayClosePeriod, setMondayClosePeriod] = useState("PM");
  const [mondayClosed, setMondayClosed] = useState(false);
  const [tuesdayOpenPeriod, setTuesdayOpenPeriod] = useState("AM");
  const [tuesdayClosePeriod, setTuesdayClosePeriod] = useState("PM");
  const [tuesdayClosed, setTuesdayClosed] = useState(false);
  const [wednesdayOpenPeriod, setWednesdayOpenPeriod] = useState("AM");
  const [wednesdayClosePeriod, setWednesdayClosePeriod] = useState("PM");
  const [wednesdayClosed, setWednesdayClosed] = useState(false);
  const [thursdayOpenPeriod, setThursdayOpenPeriod] = useState("AM");
  const [thursdayClosePeriod, setThursdayClosePeriod] = useState("PM");
  const [thursdayClosed, setThursdayClosed] = useState(false);
  const [fridayOpenPeriod, setFridayOpenPeriod] = useState("AM");
  const [fridayClosePeriod, setFridayClosePeriod] = useState("PM");
  const [fridayClosed, setFridayClosed] = useState(false);
  const [saturdayOpenPeriod, setSaturdayOpenPeriod] = useState("AM");
  const [saturdayClosePeriod, setSaturdayClosePeriod] = useState("PM");
  const [saturdayClosed, setSaturdayClosed] = useState(false);
  const [sundayOpenPeriod, setSundayOpenPeriod] = useState("AM");
  const [sundayClosePeriod, setSundayClosePeriod] = useState("PM");
  const [sundayClosed, setSundayClosed] = useState(false);
  const [hours, setHours] = useState({
    mondayOpen: "",
    mondayClose: "",
    tuesdayOpen: "",
    tuesdayClose: "",
    wednesdayOpen: "",
    wednesdayClose: "",
    thursdayOpen: "",
    thursdayClose: "",
    fridayOpen: "",
    fridayClose: "",
    saturdayOpen: "",
    saturdayClose: "",
    sundayOpen: "",
    sundayClose: "",
  });
  const [locations, setLocations] = useState([]);
  const [services, setServices] = useState([]);
  const [locationsSelected, setLocationsSelected] = useState([]);
  const [servicesSelected, setServicesSelected] = useState([]);
  const [socialMediaOptions, setSocialMediaOptions] = useState(false);
  const [additionalOptions, setAdditionalOptions] = useState(false);
  const [hourOptions, setHourOptions] = useState(false);
  const [noNameAlert, setNoNameAlert] = useState(true);

  let navigate = useNavigate();

  //const business = {name, address, phone, email, notes, experience, certifications};

  /*
    useEffect(() => {
      
      let authToken = sessionStorage.getItem('Auth Token')
  
      if (authToken) {
          navigate('/business')
      }
  
      if (!authToken) {
          navigate('/')
      }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    */
  useEffect(() => {
    loadLocations();
  }, []);

  useEffect(() => {
    loadServices();
  }, []);

  useEffect(() => {
    if (name === "") {
      setNoNameAlert(true);
    } else {
      setNoNameAlert(false);
    }
  }, [name]);

  const loadLocations = () => {
    getAllLocations().then((l) => {
      //console.log(l[0].locations);
      const tempArray = l[0].locations.sort();
      const tempArray1 = tempArray.splice(1, tempArray.length);
      setLocations(tempArray1);
    });
  };

  const loadServices = () => {
    getAllServices().then((s) => {
      //console.log(s[0].services);
      const tempArray = s[0].services.sort();
      const tempArray1 = tempArray.splice(1, tempArray.length);
      setServices(tempArray1);
    });
  };

  const handleBusinessClick = (e) => {
    e.preventDefault();

    toast.success("Creating Business, give us a second!");
    toast.success("Business has been created");

    const editableHours = [
      {
        mondayClosed: mondayClosed,
        mondayOpen: hours.mondayOpen,
        mondayOpenPeriod: mondayOpenPeriod,
        mondayClose: hours.mondayClose,
        mondayClosePeriod: mondayClosePeriod,
        tuesdayClosed: tuesdayClosed,
        tuesdayOpen: hours.tuesdayOpen,
        tuesdayOpenPeriod: tuesdayOpenPeriod,
        tuesdayClose: hours.tuesdayClose,
        tuesdayClosePeriod: tuesdayClosePeriod,
        wednesdayClosed: wednesdayClosed,
        wednesdayOpen: hours.wednesdayOpen,
        wednesdayOpenPeriod: wednesdayOpenPeriod,
        wednesdayClose: hours.wednesdayClose,
        wednesdayClosePeriod: wednesdayClosePeriod,
        thursdayClosed: thursdayClosed,
        thursdayOpen: hours.thursdayOpen,
        thursdayOpenPeriod: thursdayOpenPeriod,
        thursdayClose: hours.thursdayClose,
        thursdayClosePeriod: thursdayClosePeriod,
        fridayClosed: fridayClosed,
        fridayOpen: hours.fridayOpen,
        fridayOpenPeriod: fridayOpenPeriod,
        fridayClose: hours.fridayClose,
        fridayClosePeriod: fridayClosePeriod,
        saturdayClosed: saturdayClosed,
        saturdayOpen: hours.saturdayOpen,
        saturdayOpenPeriod: saturdayOpenPeriod,
        saturdayClose: hours.saturdayClose,
        saturdayClosePeriod: saturdayClosePeriod,
        sundayClosed: sundayClosed,
        sundayOpen: hours.sundayOpen,
        sundayOpenPeriod: sundayOpenPeriod,
        sundayClose: hours.sundayClose,
        sundayClosePeriod: sundayClosePeriod,
      },
    ];

    const newHours = {
      Monday: mondayClosed
        ? "Closed"
        : hours.mondayOpen +
          " " +
          mondayOpenPeriod +
          " - " +
          hours.mondayClose +
          " " +
          mondayClosePeriod,
      Tuesday: tuesdayClosed
        ? "Closed"
        : hours.tuesdayOpen +
          " " +
          tuesdayOpenPeriod +
          " - " +
          hours.tuesdayClose +
          " " +
          tuesdayClosePeriod,
      Wednesday: wednesdayClosed
        ? "Closed"
        : hours.wednesdayOpen +
          " " +
          wednesdayOpenPeriod +
          " - " +
          hours.wednesdayClose +
          " " +
          wednesdayClosePeriod,
      Thursday: thursdayClosed
        ? "Closed"
        : hours.thursdayOpen +
          " " +
          thursdayOpenPeriod +
          " - " +
          hours.thursdayClose +
          " " +
          thursdayClosePeriod,
      Friday: fridayClosed
        ? "Closed"
        : hours.fridayOpen +
          " " +
          fridayOpenPeriod +
          " - " +
          hours.fridayClose +
          " " +
          fridayClosePeriod,
      Saturday: saturdayClosed
        ? "Closed"
        : hours.saturdayOpen +
          " " +
          saturdayOpenPeriod +
          " - " +
          hours.saturdayClose +
          " " +
          saturdayClosePeriod,
      Sunday: sundayClosed
        ? "Closed"
        : hours.sundayOpen +
          " " +
          sundayOpenPeriod +
          " - " +
          hours.sundayClose +
          " " +
          sundayClosePeriod,
    };

    console.log(newHours);

    const business = {
      name: name,
      street: street,
      city: city,
      state: state,
      zipCode: zipCode,
      phone: phone,
      email: email,
      notes: notes,
      experience: experience,
      certifications: certifications,
      website: website,
      twitter: twitter,
      instagram: instagram,
      linkedIn: linkedIn,
      facebook: facebook,
      acceptingClients: acceptingClients,
      byApptOnly: byApptOnly,
      active: true,
      contactName: contactName,
      locations: locationsSelected,
      services: servicesSelected,
      hours: newHours,
      editableHours: editableHours,
      uid: currentUser.uid,
    };

    createBusiness(business).then((res) => {
      console.log(res);
      console.log("Clearing Data");
      setName("");
      setStreet("");
      setCity("");
      setState("");
      setZipCode("");
      setPhone("");
      setEmail("");
      setNotes("");
      setWebsite("");
      setExperience("");
      setCertifications("");
      setTwitter("");
      setInstagram("");
      setLinkedIn("");
      setFacebook("");
      setContactName("");
      setByApptOnly("No");
      setAcceptingClients("Yes");
      setLocationsSelected([]);
      setServicesSelected([]);
      setHours({
        mondayOpen: "",
        mondayClose: "",
        tuesdayOpen: "",
        tuesdayClose: "",
        wednesdayOpen: "",
        wednesdayClose: "",
        thursdayOpen: "",
        thursdayClose: "",
        fridayOpen: "",
        fridayClose: "",
        saturdayOpen: "",
        saturdayClose: "",
        sundayOpen: "",
        sundayClose: "",
      });
      navigate("/Manage");
    });
  };

  const handleLocationsChange = (event) => {
    const {
      target: { value },
    } = event;
    setLocationsSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleServicesChange = (event) => {
    const {
      target: { value },
    } = event;
    setServicesSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const MultiSelectServices = () => {
    return (
      <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-checkbox-label">
            Services Offered
          </InputLabel>
          <Select
            required
            labelId="demo-multiple-checkbox-label"
            text="not seeing"
            id="demo-multiple-checkbox"
            multiple
            value={servicesSelected}
            onChange={handleServicesChange}
            input={<OutlinedInput label="Services Offered" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {services.map((service) => (
              <MenuItem key={service} value={service}>
                <Checkbox checked={servicesSelected.indexOf(service) > -1} />
                <ListItemText primary={service} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const MultiSelectLocations = () => {
    return (
      <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-checkbox-label">
            Locations Serviced
          </InputLabel>
          <Select
            required
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={locationsSelected}
            onChange={handleLocationsChange}
            input={<OutlinedInput label="Services Offered" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {locations.map((location) => (
              <MenuItem key={location} value={location}>
                <Checkbox checked={locationsSelected.indexOf(location) > -1} />
                <ListItemText primary={location} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Card
            elevation={5}
            sx={{
              maxWidth: 2000,
              minHeight: 800,
              backgroundColor: "#ECECEC",
              margin: 2,
            }}
          >
            <h1
              style={{
                fontSize: "3rem",
                flexWrap: "wrap",
                lineHeight: "normal",
              }}
            >
              Big Horn Basin
            </h1>
            <h1
              style={{
                fontSize: "3rem",
                flexWrap: "wrap",
                lineHeight: "normal",
              }}
            >
              Service Directory
            </h1>

            <br />
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              alignItems="center"
              justifyContent="center"
              noValidate
              autoComplete="off"
            >
              <Stack
                spacing={{ xs: 1, sm: 2, md: 4 }}
                direction={{ xs: "column", sm: "column", md: "row" }}
              >
                <TextField
                  required
                  id="outlined-required"
                  label="Business Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  id="outlined"
                  label="Contact Name"
                  value={contactName}
                  onChange={(e) => setContactName(e.target.value)}
                />
                <MuiPhoneNumber
                  defaultCountry={"us"}
                  label="Phone Number"
                  onChange={(value) => setPhone(value)}
                />
              </Stack>
              <Stack
                spacing={{ xs: 1, sm: 2, md: 4 }}
                direction={{ xs: "column", sm: "column", md: "row" }}
              >
                <TextField
                  id="outlined-required"
                  label="Street"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
                <TextField
                  id="outlined"
                  label="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
                <TextField
                  id="outlined"
                  label="State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
                <TextField
                  id="outlined"
                  label="Zip"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
              </Stack>
              <Stack
                spacing={{ xs: 1, sm: 2, md: 4 }}
                direction={{ xs: "column", sm: "column", md: "row" }}
              >
                <MultiSelectLocations />
                <MultiSelectServices />
              </Stack>
            </Box>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <h3 className="float-right">Hours</h3>
              {hourOptions ? (
                <ArrowUpwardIcon
                  onClick={(e) => setHourOptions(!hourOptions)}
                />
              ) : (
                <ArrowDownwardIcon
                  onClick={(e) => setHourOptions(!hourOptions)}
                />
              )}
            </Stack>

            {hourOptions && (
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                alignItems="center"
                justifyContent="center"
                noValidate
                autoComplete="off"
              >
                <Stack>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <h4>Monday</h4>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Closed"
                      onClick={(e) => setMondayClosed(!mondayClosed)}
                    />
                    <Stack
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      direction={{ xs: "column", sm: "column", md: "row" }}
                    >
                      {!mondayClosed && (
                        <>
                          <TextField
                            id="outlined-required"
                            label="Open"
                            type="number"
                            value={hours.mondayOpen}
                            onChange={(e) => {
                              let tempInt = "";
                              if (!isNaN(e.target.value)) {
                                tempInt = e.target.value;
                                if (parseInt(e.target.value) < 1) {
                                  tempInt = "1";
                                }
                                if (parseInt(e.target.value) > 12) {
                                  tempInt = "12";
                                }
                                console.log(tempInt);
                                console.log(typeof tempInt);
                                setHours({
                                  ...hours,
                                  mondayOpen: tempInt,
                                });
                              } else {
                                setHours({
                                  ...hours,
                                  mondayOpen: "",
                                });
                              }
                            }}
                          />
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={mondayOpenPeriod}
                            name="radio-buttons-group"
                            onChange={(e) =>
                              setMondayOpenPeriod(e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="AM"
                              control={<Radio />}
                              label="AM"
                            />
                            <FormControlLabel
                              value="PM"
                              control={<Radio />}
                              label="PM"
                            />
                          </RadioGroup>

                          <TextField
                            id="outlined"
                            label="Close"
                            value={hours.mondayClose}
                            onChange={(e) => {
                              let tempInt = "";
                              if (!isNaN(e.target.value)) {
                                tempInt = e.target.value;
                                if (parseInt(e.target.value) < 1) {
                                  tempInt = 1;
                                }
                                if (parseInt(e.target.value) > 12) {
                                  tempInt = 12;
                                }
                                setHours({ ...hours, mondayClose: tempInt });
                              } else {
                                setHours({
                                  ...hours,
                                  mondayClose: "",
                                });
                              }
                            }}
                          />
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={mondayClosePeriod}
                            name="radio-buttons-group"
                            onChange={(e) =>
                              setMondayClosePeriod(e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="AM"
                              control={<Radio />}
                              label="AM"
                            />
                            <FormControlLabel
                              value="PM"
                              control={<Radio />}
                              label="PM"
                            />
                          </RadioGroup>
                        </>
                      )}
                    </Stack>
                    <h4>Tuesday</h4>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Closed"
                      onClick={(e) => setTuesdayClosed(!tuesdayClosed)}
                    />
                    <Stack
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      direction={{ xs: "column", sm: "column", md: "row" }}
                    >
                      {!tuesdayClosed && (
                        <>
                          <TextField
                            id="outlined-required"
                            label="Open"
                            type="number"
                            value={hours.tuesdayOpen}
                            onChange={(e) => {
                              let tempInt = "";
                              if (!isNaN(e.target.value)) {
                                tempInt = e.target.value;
                                if (parseInt(e.target.value) < 1) {
                                  tempInt = 1;
                                }
                                if (parseInt(e.target.value) > 12) {
                                  tempInt = 12;
                                }
                                setHours({ ...hours, tuesdayOpen: tempInt });
                              } else {
                                setHours({
                                  ...hours,
                                  tuesdayOpen: "",
                                });
                              }
                            }}
                          />
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={tuesdayOpenPeriod}
                            name="radio-buttons-group"
                            onChange={(e) =>
                              setTuesdayOpenPeriod(e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="AM"
                              control={<Radio />}
                              label="AM"
                            />
                            <FormControlLabel
                              value="PM"
                              control={<Radio />}
                              label="PM"
                            />
                          </RadioGroup>

                          <TextField
                            id="outlined"
                            label="Close"
                            value={hours.tuesdayClose}
                            onChange={(e) => {
                              let tempInt = "";
                              if (!isNaN(e.target.value)) {
                                tempInt = e.target.value;
                                if (parseInt(e.target.value) < 1) {
                                  tempInt = 1;
                                }
                                if (parseInt(e.target.value) > 12) {
                                  tempInt = 12;
                                }
                                setHours({ ...hours, tuesdayClose: tempInt });
                              } else {
                                setHours({
                                  ...hours,
                                  tuesdayClose: "",
                                });
                              }
                            }}
                          />
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={tuesdayClosePeriod}
                            name="radio-buttons-group"
                            onChange={(e) =>
                              setTuesdayClosePeriod(e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="AM"
                              control={<Radio />}
                              label="AM"
                            />
                            <FormControlLabel
                              value="PM"
                              control={<Radio />}
                              label="PM"
                            />
                          </RadioGroup>
                        </>
                      )}
                    </Stack>
                    <h4>Wednesday</h4>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Closed"
                      onClick={(e) => setWednesdayClosed(!wednesdayClosed)}
                    />
                    <Stack
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      direction={{ xs: "column", sm: "column", md: "row" }}
                    >
                      {!wednesdayClosed && (
                        <>
                          <TextField
                            id="outlined-required"
                            label="Open"
                            type="number"
                            value={hours.wednesdayOpen}
                            onChange={(e) => {
                              let tempInt = "";
                              if (!isNaN(e.target.value)) {
                                tempInt = e.target.value;
                                if (parseInt(e.target.value) < 1) {
                                  tempInt = 1;
                                }
                                if (parseInt(e.target.value) > 12) {
                                  tempInt = 12;
                                }
                                setHours({ ...hours, wednesdayOpen: tempInt });
                              } else {
                                setHours({
                                  ...hours,
                                  wednesdayOpen: "",
                                });
                              }
                            }}
                          />
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={wednesdayOpenPeriod}
                            name="radio-buttons-group"
                            onChange={(e) =>
                              setWednesdayOpenPeriod(e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="AM"
                              control={<Radio />}
                              label="AM"
                            />
                            <FormControlLabel
                              value="PM"
                              control={<Radio />}
                              label="PM"
                            />
                          </RadioGroup>

                          <TextField
                            id="outlined"
                            label="Close"
                            value={hours.wednesdayClose}
                            onChange={(e) => {
                              let tempInt = "";
                              if (!isNaN(e.target.value)) {
                                tempInt = e.target.value;
                                if (parseInt(e.target.value) < 1) {
                                  tempInt = 1;
                                }
                                if (parseInt(e.target.value) > 12) {
                                  tempInt = 12;
                                }
                                setHours({ ...hours, wednesdayClose: tempInt });
                              } else {
                                setHours({
                                  ...hours,
                                  wednesdayClose: "",
                                });
                              }
                            }}
                          />
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={wednesdayClosePeriod}
                            name="radio-buttons-group"
                            onChange={(e) =>
                              setWednesdayClosePeriod(e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="AM"
                              control={<Radio />}
                              label="AM"
                            />
                            <FormControlLabel
                              value="PM"
                              control={<Radio />}
                              label="PM"
                            />
                          </RadioGroup>
                        </>
                      )}
                    </Stack>
                    <h4>Thursday</h4>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Closed"
                      onClick={(e) => setThursdayClosed(!thursdayClosed)}
                    />
                    <Stack
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      direction={{ xs: "column", sm: "column", md: "row" }}
                    >
                      {!thursdayClosed && (
                        <>
                          <TextField
                            id="outlined-required"
                            label="Open"
                            type="number"
                            value={hours.thursdayOpen}
                            onChange={(e) => {
                              let tempInt = "";
                              if (!isNaN(e.target.value)) {
                                tempInt = e.target.value;
                                if (parseInt(e.target.value) < 1) {
                                  tempInt = 1;
                                }
                                if (parseInt(e.target.value) > 12) {
                                  tempInt = 12;
                                }
                                setHours({ ...hours, thursdayOpen: tempInt });
                              } else {
                                setHours({
                                  ...hours,
                                  thursdayOpen: "",
                                });
                              }
                            }}
                          />
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={thursdayOpenPeriod}
                            name="radio-buttons-group"
                            onChange={(e) =>
                              setThursdayOpenPeriod(e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="AM"
                              control={<Radio />}
                              label="AM"
                            />
                            <FormControlLabel
                              value="PM"
                              control={<Radio />}
                              label="PM"
                            />
                          </RadioGroup>

                          <TextField
                            id="outlined"
                            label="Close"
                            value={hours.thursdayClose}
                            onChange={(e) => {
                              let tempInt = "";
                              if (!isNaN(e.target.value)) {
                                tempInt = e.target.value;
                                if (parseInt(e.target.value) < 1) {
                                  tempInt = 1;
                                }
                                if (parseInt(e.target.value) > 12) {
                                  tempInt = 12;
                                }
                                setHours({ ...hours, thursdayClose: tempInt });
                              } else {
                                setHours({
                                  ...hours,
                                  thursdayClose: "",
                                });
                              }
                            }}
                          />
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={thursdayClosePeriod}
                            name="radio-buttons-group"
                            onChange={(e) =>
                              setThursdayClosePeriod(e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="AM"
                              control={<Radio />}
                              label="AM"
                            />
                            <FormControlLabel
                              value="PM"
                              control={<Radio />}
                              label="PM"
                            />
                          </RadioGroup>
                        </>
                      )}
                    </Stack>
                    <h4>Friday</h4>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Closed"
                      onClick={(e) => setFridayClosed(!fridayClosed)}
                    />
                    <Stack
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      direction={{ xs: "column", sm: "column", md: "row" }}
                    >
                      {!fridayClosed && (
                        <>
                          <TextField
                            id="outlined-required"
                            label="Open"
                            type="number"
                            value={hours.fridayOpen}
                            onChange={(e) => {
                              let tempInt = "";
                              if (!isNaN(e.target.value)) {
                                tempInt = e.target.value;
                                if (parseInt(e.target.value) < 1) {
                                  tempInt = 1;
                                }
                                if (parseInt(e.target.value) > 12) {
                                  tempInt = 12;
                                }
                                setHours({ ...hours, fridayOpen: tempInt });
                              } else {
                                setHours({
                                  ...hours,
                                  fridayOpen: "",
                                });
                              }
                            }}
                          />
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={fridayOpenPeriod}
                            name="radio-buttons-group"
                            onChange={(e) =>
                              setFridayOpenPeriod(e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="AM"
                              control={<Radio />}
                              label="AM"
                            />
                            <FormControlLabel
                              value="PM"
                              control={<Radio />}
                              label="PM"
                            />
                          </RadioGroup>

                          <TextField
                            id="outlined"
                            label="Close"
                            value={hours.fridayClose}
                            onChange={(e) => {
                              let tempInt = "";
                              if (!isNaN(e.target.value)) {
                                tempInt = e.target.value;
                                if (parseInt(e.target.value) < 1) {
                                  tempInt = 1;
                                }
                                if (parseInt(e.target.value) > 12) {
                                  tempInt = 12;
                                }
                                setHours({ ...hours, fridayClose: tempInt });
                              } else {
                                setHours({
                                  ...hours,
                                  fridayClose: "",
                                });
                              }
                            }}
                          />
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={fridayClosePeriod}
                            name="radio-buttons-group"
                            onChange={(e) =>
                              setFridayClosePeriod(e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="AM"
                              control={<Radio />}
                              label="AM"
                            />
                            <FormControlLabel
                              value="PM"
                              control={<Radio />}
                              label="PM"
                            />
                          </RadioGroup>
                        </>
                      )}
                    </Stack>

                    <h4>Saturday</h4>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Closed"
                      onClick={(e) => setSaturdayClosed(!saturdayClosed)}
                    />
                    <Stack
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      direction={{ xs: "column", sm: "column", md: "row" }}
                    >
                      {!saturdayClosed && (
                        <>
                          <TextField
                            id="outlined-required"
                            label="Open"
                            type="number"
                            value={hours.saturdayOpen}
                            onChange={(e) => {
                              let tempInt = "";
                              if (!isNaN(e.target.value)) {
                                tempInt = e.target.value;
                                if (parseInt(e.target.value) < 1) {
                                  tempInt = 1;
                                }
                                if (parseInt(e.target.value) > 12) {
                                  tempInt = 12;
                                }
                                setHours({ ...hours, saturdayOpen: tempInt });
                              } else {
                                setHours({
                                  ...hours,
                                  saturdayOpen: "",
                                });
                              }
                            }}
                          />
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={saturdayOpenPeriod}
                            name="radio-buttons-group"
                            onChange={(e) =>
                              setSaturdayOpenPeriod(e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="AM"
                              control={<Radio />}
                              label="AM"
                            />
                            <FormControlLabel
                              value="PM"
                              control={<Radio />}
                              label="PM"
                            />
                          </RadioGroup>

                          <TextField
                            id="outlined"
                            label="Close"
                            value={hours.saturdayClose}
                            onChange={(e) => {
                              let tempInt = "";
                              if (!isNaN(e.target.value)) {
                                tempInt = e.target.value;
                                if (parseInt(e.target.value) < 1) {
                                  tempInt = 1;
                                }
                                if (parseInt(e.target.value) > 12) {
                                  tempInt = 12;
                                }
                                setHours({ ...hours, saturdayClose: tempInt });
                              } else {
                                setHours({
                                  ...hours,
                                  sataurdayClose: "",
                                });
                              }
                            }}
                          />
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={saturdayClosePeriod}
                            name="radio-buttons-group"
                            onChange={(e) =>
                              setSaturdayClosePeriod(e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="AM"
                              control={<Radio />}
                              label="AM"
                            />
                            <FormControlLabel
                              value="PM"
                              control={<Radio />}
                              label="PM"
                            />
                          </RadioGroup>
                        </>
                      )}
                    </Stack>
                    <h4>Sunday</h4>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Closed"
                      onClick={(e) => setSundayClosed(!sundayClosed)}
                    />
                    <Stack
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      direction={{ xs: "column", sm: "column", md: "row" }}
                    >
                      {!sundayClosed && (
                        <>
                          <TextField
                            id="outlined-required"
                            label="Open"
                            type="number"
                            value={hours.sundayOpen}
                            onChange={(e) => {
                              let tempInt = "";
                              if (!isNaN(e.target.value)) {
                                tempInt = e.target.value;
                                if (parseInt(e.target.value) < 1) {
                                  tempInt = 1;
                                }
                                if (parseInt(e.target.value) > 12) {
                                  tempInt = 12;
                                }
                                setHours({ ...hours, sundayOpen: tempInt });
                              } else {
                                setHours({
                                  ...hours,
                                  sundayOpen: "",
                                });
                              }
                            }}
                          />
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={sundayOpenPeriod}
                            name="radio-buttons-group"
                            onChange={(e) =>
                              setSundayOpenPeriod(e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="AM"
                              control={<Radio />}
                              label="AM"
                            />
                            <FormControlLabel
                              value="PM"
                              control={<Radio />}
                              label="PM"
                            />
                          </RadioGroup>

                          <TextField
                            id="outlined"
                            label="Close"
                            value={hours.sundayClose}
                            onChange={(e) => {
                              let tempInt = "";
                              if (!isNaN(e.target.value)) {
                                tempInt = e.target.value;
                                if (parseInt(e.target.value) < 1) {
                                  tempInt = 1;
                                }
                                if (parseInt(e.target.value) > 12) {
                                  tempInt = 12;
                                }
                                setHours({ ...hours, sundayClose: tempInt });
                              } else {
                                setHours({
                                  ...hours,
                                  sundayClose: "",
                                });
                              }
                            }}
                          />
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={sundayClosePeriod}
                            name="radio-buttons-group"
                            onChange={(e) =>
                              setSundayClosePeriod(e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="AM"
                              control={<Radio />}
                              label="AM"
                            />
                            <FormControlLabel
                              value="PM"
                              control={<Radio />}
                              label="PM"
                            />
                          </RadioGroup>
                        </>
                      )}
                    </Stack>
                  </MuiPickersUtilsProvider>
                </Stack>
              </Box>
            )}
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <h3 className="float-right">Additional</h3>
              {additionalOptions ? (
                <ArrowUpwardIcon
                  onClick={(e) => setAdditionalOptions(!additionalOptions)}
                />
              ) : (
                <ArrowDownwardIcon
                  onClick={(e) => setAdditionalOptions(!additionalOptions)}
                />
              )}
            </Stack>

            {additionalOptions && (
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                alignItems="center"
                justifyContent="center"
                noValidate
                autoComplete="off"
              >
                <Stack
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                  direction={{ xs: "column", sm: "column", md: "row" }}
                >
                  <TextField
                    id="outlined"
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    id="outlined"
                    label="Website"
                    helperText="Input full url"
                    type="url"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </Stack>
                <Stack
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                  direction={{ xs: "column", sm: "column", md: "row" }}
                >
                  <TextField
                    id="outlined-required"
                    label="Experience"
                    value={experience}
                    onChange={(e) => setExperience(e.target.value)}
                  />
                  <TextField
                    id="outlined"
                    label="Certifications"
                    value={certifications}
                    onChange={(e) => setCertifications(e.target.value)}
                  />
                  <TextField
                    id="outlined"
                    label="Notes"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Stack>
                <Stack
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                  direction={{ xs: "column", sm: "column", md: "row" }}
                >
                  <FormControl>
                    <FormLabel id="demo-form-control-label-placement">
                      Accepting New Clients?
                    </FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={acceptingClients}
                      onChange={(e) => setAcceptingClients(e.target.value)}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel id="demo-form-control-label-placement">
                      By Appointment Only?
                    </FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={byApptOnly}
                      onChange={(e) => setByApptOnly(e.target.value)}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>
            )}
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <h3 className="float-right">Social Media</h3>
              {socialMediaOptions ? (
                <ArrowUpwardIcon
                  onClick={(e) => setSocialMediaOptions(!socialMediaOptions)}
                />
              ) : (
                <ArrowDownwardIcon
                  onClick={(e) => setSocialMediaOptions(!socialMediaOptions)}
                />
              )}
            </Stack>

            {socialMediaOptions && (
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                alignItems="center"
                justifyContent="center"
                noValidate
                autoComplete="off"
              >
                <Stack
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                  direction={{ xs: "column", sm: "column", md: "row" }}
                >
                  <TextField
                    id="outlined-required"
                    label="Facebook"
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                  />
                  <TextField
                    id="outlined"
                    label="Instagram"
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                  />
                  <TextField
                    id="outlined-required"
                    label="LinkedIn"
                    value={linkedIn}
                    onChange={(e) => setLinkedIn(e.target.value)}
                  />
                  <TextField
                    id="outlined"
                    label="Twitter"
                    value={twitter}
                    onChange={(e) => setTwitter(e.target.value)}
                  />
                </Stack>
              </Box>
            )}
            <p>
              <i>
                Please verify your information is correct before submitting.
              </i>
            </p>

            <Button
              onClick={handleBusinessClick}
              variant="contained"
              disabled={noNameAlert}
            >
              Add Business!
            </Button>
            <br />
          </Card>
        </Grid>
        <ToastContainer />
      </ThemeProvider>
    </>
  );
};

export default DataInput;
