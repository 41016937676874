import React, { useState, useContext, useEffect } from "react";
import {
  signInWithGooglePopup,
  signInAuthUserWithEmailAndPassword,
} from "../firebase";
import { useNavigate } from "react-router-dom";
import { createUserDocumentFromAuth } from "../functions/users";
import { UserContext } from "../contexts/user.context";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import CssBaseline from "@mui/material/CssBaseline";
import { Card, CardContent, Grid, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Vector from "../images/Vector.png";
import { ToastContainer, toast } from "react-toastify";

const defaultFormFields = {
  email: "",
  password: "",
};

const Login = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;

  const { currentUser, setCurrentUser } = useContext(UserContext);
  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate("/home");
    }
  }, [currentUser]);

  const signInWithGoogle = async () => {
    const { user } = await signInWithGooglePopup();
    setCurrentUser(user);
    console.log(user);
    await createUserDocumentFromAuth(user);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { user } = await signInAuthUserWithEmailAndPassword(
        email,
        password
      );
      setCurrentUser(user);
      resetFormFields();
    } catch (error) {
      switch (error.code) {
        case "auth/wrong-password":
          toast("Incorrect email/password. Try again");
          break;
        case "auth/user-not-found":
          toast("Email not found");
          break;
        default:
          console.log(error);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  const LoginForm = () => (
    <form onSubmit={handleSubmit}>
      <TextField
        type="email"
        className="form-control"
        value={email}
        name="email"
        onChange={handleChange}
        autoFocus
        label="Enter Email"
      />
      <br />
      <br />
      <TextField
        type="password"
        className="form-control"
        value={password}
        name="password"
        onChange={handleChange}
        label="Enter Password"
      />
      <br />
      <br />
      <Button
        type="submit"
        variant="contained"
        /*className="mb-3"
        block
        /*shape="round"*/
        disabled={!email || password.length < 6}
      >
        Sign In
      </Button>

      <br />
    </form>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Card
            elevation={5}
            sx={{
              backgroundColor: "#ECECEC",
              margin: 2,
            }}
          >
            <CardContent>
              <div className="container p-5">
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <h4 style={{ fontSize: "2rem" }}>Welcome Back!</h4>

                    {LoginForm()}
                    <br />
                    <Button
                      style={{ color: "black" }}
                      variant="contained"
                      color="secondary"
                      onClick={signInWithGoogle}
                    >
                      Sign in With Google
                    </Button>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </div>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default Login;
