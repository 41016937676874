import React, { useState, useEffect } from "react";
import {
  getAllLocations,
  getAllServices,
  searchBusinesses,
  addSearch,
} from "../functions/business";
import { Link } from "react-router-dom";
import BusCard from "../components/card";
//import SignupForm from "../components/signupForm";
//import DataInput from "./dataInput";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme, useMediaQuery } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import CssBaseline from "@mui/material/CssBaseline";
import {
  CardActions,
  CardContent,
  colors,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { Card } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Vector from "../images/Vector.png";
import { fontSize } from "@mui/system";
import { SocialIcon } from "react-social-icons";
import { Stack } from "@mui/material";

const Business = () => {
  const [jobLocations, setJobLocations] = useState([]);
  const [jobLocation, setJobLocation] = useState("Any");
  const [jobServices, setJobServices] = useState([]);
  const [jobService, setJobService] = useState(".Any");
  const [searchResults, setSearchResults] = useState([]);
  const [open, setOpen] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [businessSelected, setBusinessSelected] = useState(0);
  const [selectedBusinessData, setSelectedBusinessData] = useState({});
  const [noSearchResults, setNoSearchResults] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    loadLocations();
  }, []);

  useEffect(() => {
    loadServices();
  }, []);

  const loadLocations = () => {
    getAllLocations().then((l) => {
      setJobLocations(l[0].locations.sort());
    });
  };

  const loadServices = () => {
    getAllServices().then((s) => {
      setJobServices(s[0].services.sort());
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(jobLocation);
    //console.log(jobService);

    searchBusinesses(jobService).then((b) => {
      //console.log(b)
      let matches = [];
      b.forEach((obj) => {
        obj.locations &&
          obj.locations.map((c) => c === jobLocation && matches.push(obj));
      });
      /*
        let all = b.reduce((prev, next) => prev.concat(next.locations), []);
        let results = all.filter(obj => obj.locations === jobLocation);
        */
      if (jobLocation === "Any") {
        matches = b;
      }
      console.log(matches);
      const sortedDateMatches = matches.sort(
        (a, b) => a.sign_up_date - b.sign_up_date
      );
      const sortedMatches = sortedDateMatches.sort((a, b) => b.rank - a.rank);
      console.log(sortedMatches);
      setSearchResults(matches);
      if (matches.length === 0) {
        setNoSearchResults(true);
      } else {
        setNoSearchResults(false);
      }
    });

    const searchData = {
      service: jobService,
      location: jobLocation,
    };

    addSearch(searchData).then((b) => console.log(b));
  };

  const SelectService = () => {
    return (
      <FormControl sx={{ m: 1, minWidth: 300 }}>
        <InputLabel id="demo-simple-select-helper-label">
          Service Needed
        </InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={jobService}
          label="Service Needed"
          onChange={(e) => setJobService(e.target.value)}
        >
          {jobServices.map((s, index) => (
            <MenuItem key={s} value={s}>
              {s}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const SelectLocation = () => {
    return (
      <FormControl sx={{ m: 1, minWidth: 300 }}>
        <InputLabel id="demo-simple-select-helper-label">
          Location of Job
        </InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={jobLocation}
          label="Location of Job"
          onChange={(e) => setJobLocation(e.target.value)}
        >
          {jobLocations.map((s, index) => (
            <MenuItem key={s} value={s}>
              {s}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const onClick = (b) => {
    //console.log(index);
    console.log(b);
    //setBusinessSelected(searchResults[index]);
    setSelectedBusinessData(b);
    //setCardIndex(index)
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleTermsClose = (e) => {
    setOpenTerms(false);
  };

  const onTermsOpen = () => {
    setOpenTerms(true);
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Card
            elevation={5}
            sx={{
              backgroundColor: "#ECECEC",
              margin: 2,
            }}
          >
            <CardContent>
              <form onSubmit={handleSubmit}>
                <SelectService />
                <br />
                <SelectLocation />
                <br />
                <p>
                  By clicking "search" you agree to our {""}
                  <a
                    onClick={() => setOpenTerms(true)}
                    style={{ color: "Blue", cursor: "pointer" }}
                  >
                    terms
                  </a>
                  .
                </p>
                <br />
                <Button variant="contained" type="submit">
                  Search
                </Button>
              </form>
            </CardContent>
          </Card>
          <h1
            style={{
              fontSize: "2rem",
              color: "#ECECEC",
              lineHeight: "normal",
            }}
          >
            Service Provider?&nbsp;&nbsp;
            <Button variant="contained" component={Link} to="/register">
              Sign-Up Today
            </Button>
          </h1>
          {noSearchResults && (
            <>
              <h3>Your search contained no results!</h3>
              <h3>
                We are in the process of building up the site. If you know of
                someone that provides this service, send them our way!
              </h3>
            </>
          )}

          <div className="row m2">
            <Grid container justify="space-around" spacing={8}>
              {searchResults &&
                searchResults.map((b, index) => (
                  <>
                    <Grid key={index} item xs margin={2}>
                      <Card
                        key={index}
                        variant="outlined"
                        elevation={5}
                        sx={{
                          minWidth: 250,
                          maxWidth: 275,
                          minHeight: 250,
                          backgroundColor: "#ECECEC",
                          margin: 2,
                        }}
                      >
                        <CardContent>
                          <Typography
                            sx={{
                              mb: 1.5,
                              fontSize: "1rem",
                              fontFamily: "Merriweather",
                            }}
                            color="text.primary"
                          ></Typography>
                          <Typography
                            variant="body2"
                            fontSize="1.25rem"
                            fontWeight="bold"
                            fontFamily="Merriweather"
                          >
                            {b && b.name}

                            <br />
                          </Typography>
                          <Typography variant="body1">
                            {b && b.contactName}
                          </Typography>
                          <br />
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: ".75rem",
                              fontFamily: "Merriweather",
                            }}
                          >
                            Years of Experience
                          </p>
                          <Typography
                            variant="body2"
                            textAlign="center"
                            fontWeight="bold"
                          >
                            {b && b.experience}
                            <br />
                          </Typography>
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: ".75rem",
                              fontFamily: "Merriweather",
                            }}
                          >
                            Accepting New Clients?
                          </p>
                          <Typography
                            variant="body2"
                            textAlign="center"
                            fontWeight="bold"
                          >
                            {b && b.acceptingClients}

                            <br />
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button
                            /*size="medium"*/
                            fullWidth
                            variant="contained"
                            onClick={() => onClick(b)}
                          >
                            More Information
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  </>
                ))}
            </Grid>

            <ThemeProvider>
              <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fontFamily="Merriweather"
              >
                <DialogContent sx={{ backgroundColor: "#ECECEC" }}>
                  <Box
                    component="form"
                    sx={{
                      backgroundColor: "#ECECEC",
                      display: "flex",
                      flexDirection: "column",
                      "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    alignItems={isMobile ? "marginLeft" : "center"}
                    justifyContent={isMobile ? "marginLeft" : "center"}
                    noValidate
                    autoComplete="off"
                    line-height="0px"
                  >
                    <DialogTitle
                      style={{
                        fontWeight: "bold",
                        fontSize: isMobile ? "2.25rem" : "3rem",
                        alignItems: "center",
                      }}
                    >
                      {selectedBusinessData.name}
                    </DialogTitle>
                    <h3
                      style={{
                        fontWeight: "normal",
                        alignItems: "center",
                        fontSize: isMobile ? "1.5rem" : "2rem",
                      }}
                    >
                      {selectedBusinessData.contactName}
                    </h3>

                    <h3 style={{ fontWeight: "normal" }}>
                      {selectedBusinessData.phone}
                    </h3>

                    <Grid
                      container
                      spacing={{ xs: 1, sm: 2, md: 4 }}
                      direction={{ xs: "column", sm: "column", md: "row" }}
                    >
                      <Grid item xs={4}>
                        <div>
                          <h4 style={{ fontWeight: "normal" }}>
                            {selectedBusinessData.email}
                          </h4>

                          <a
                            href={selectedBusinessData.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ fontWeight: "normal" }}
                          >
                            {selectedBusinessData.website}
                          </a>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div>
                          <h4>Business Address:</h4>
                          <p style={{ fontWeight: "normal" }}>
                            {selectedBusinessData.street}
                          </p>
                          <p style={{ fontWeight: "normal" }}>
                            {selectedBusinessData.city}&nbsp;
                            {selectedBusinessData.state}&nbsp;
                            {selectedBusinessData.zipCode}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div>
                          <p>
                            Experience (years):{" "}
                            {selectedBusinessData.experience}
                          </p>

                          <p>
                            Certifications:{" "}
                            {selectedBusinessData.certifications}
                          </p>

                          <p>
                            Accepting New Clients:{" "}
                            {selectedBusinessData.acceptingClients}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div>
                          <p
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Hours
                          </p>
                          <p>
                            Monday:{" "}
                            {selectedBusinessData.hours &&
                              selectedBusinessData.hours["Monday"]}
                          </p>
                          <p>
                            Tuesday:{" "}
                            {selectedBusinessData.hours &&
                              selectedBusinessData.hours["Tuesday"]}
                          </p>
                          <p>
                            Wednesday:{" "}
                            {selectedBusinessData.hours &&
                              selectedBusinessData.hours["Wednesday"]}
                          </p>
                          <p>
                            Thursday:{" "}
                            {selectedBusinessData.hours &&
                              selectedBusinessData.hours["Thursday"]}
                          </p>
                          <p>
                            Friday:{" "}
                            {selectedBusinessData.hours &&
                              selectedBusinessData.hours["Friday"]}
                          </p>
                          <p>
                            Saturday:{" "}
                            {selectedBusinessData.hours &&
                              selectedBusinessData.hours["Saturday"]}
                          </p>
                          <p>
                            Sunday:{" "}
                            {selectedBusinessData.hours &&
                              selectedBusinessData.hours["Sunday"]}
                          </p>
                          <p>
                            By Appointment only?{" "}
                            {selectedBusinessData.byApptOnly}
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <p
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Locations Serviced
                        </p>
                        {selectedBusinessData.locations &&
                          selectedBusinessData.locations.map((b) => (
                            <p>{b} </p>
                          ))}
                      </Grid>
                      <Grid item xs={4}>
                        <p
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Services Provided
                        </p>
                        {selectedBusinessData.services &&
                          selectedBusinessData.services.map((b) => <p>{b}</p>)}
                        &nbsp;
                      </Grid>
                    </Grid>
                    <div>
                      <Grid item xs={12}>
                        <p
                          style={{
                            fontWeight: "normal",
                          }}
                        >
                          Notes: {selectedBusinessData.notes}
                        </p>
                      </Grid>
                    </div>
                    <div>
                      {selectedBusinessData.facebook !== "" && (
                        <SocialIcon
                          network="facebook"
                          url={selectedBusinessData.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      )}
                      &nbsp;&nbsp;
                      {selectedBusinessData.linkedIn !== "" && (
                        <SocialIcon
                          network="linkedin"
                          url={selectedBusinessData.linkedIn}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      )}
                      &nbsp;&nbsp;
                      {selectedBusinessData.twitter !== "" && (
                        <SocialIcon
                          network="twitter"
                          url={selectedBusinessData.twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      )}
                      &nbsp;&nbsp;
                      {selectedBusinessData.instagram !== "" && (
                        <SocialIcon
                          network="instagram"
                          url={selectedBusinessData.instagram}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      )}
                    </div>
                  </Box>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#ECECEC" }}>
                  <Button variant="contained" onClick={handleClose}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </ThemeProvider>
            <ThemeProvider>
              <Dialog
                open={openTerms}
                onClose={handleTermsClose}
                maxWidth="md"
                fontFamily="Merriweather"
              >
                <DialogContent sx={{ backgroundColor: "#ECECEC" }}>
                  <DialogTitle>Terms and Conditions</DialogTitle>
                  <p>
                    The information contained in this website is for general
                    information purposes only. The information is provided by
                    owners of this site and while we endeavour to keep the
                    information up to date and correct, we make no
                    representations or warranties of any kind, express or
                    implied, about the completeness, accuracy, reliability,
                    suitability or availability with respect to the website or
                    the information, products, services, or related graphics
                    contained on the website for any purpose. Any reliance you
                    place on such information is therefore strictly at your own
                    risk. In no event will we be liable for any loss or damage
                    including without limitation, indirect or consequential loss
                    or damage, or any loss or damage whatsoever arising from
                    loss of data or profits arising out of, or in connection
                    with, the use of this website. Through this website you are
                    able to link to other websites which are not under the
                    control of owner's of this site. We have no control over the
                    nature, content and availability of those sites. The
                    inclusion of any links does not necessarily imply a
                    recommendation or endorse the views expressed within them.
                    Every effort is made to keep the website up and running
                    smoothly. However, owners of this site take no
                    responsibility for, and will not be liable for, the website
                    being temporarily unavailable due to technical issues beyond
                    our control.
                  </p>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "#ECECEC" }}>
                  <Button variant="contained" onClick={handleTermsClose}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </ThemeProvider>
          </div>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default Business;
