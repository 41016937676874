import { Card } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import Logo from "../images/Logo.png";

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Card
      elevation={5}
      sx={{
        minWidth: 350,
        maxWidth: 1600,
        minHeight: 180,
        backgroundColor: "#ECECEC",
        margin: 2,
      }}
    >
      <h1
        style={{
          fontSize: isMobile ? "2.25rem" : "3.5rem",
          color: "black",
          alignItems: "center",
          flexWrap: "wrap",
          lineHeight: "normal",
        }}
      >
        Big Horn Basin Service Directory
      </h1>
      <div className="App">
        <img
          src={Logo}
          width={isMobile ? 150 : 250}
          height="auto"
          alt="Buffalo with mountain sillhouette"
        />
      </div>
    </Card>
  );
};

export default Header;
