import React, { useState } from "react";
import { autocompleteClasses, CardContent } from "@mui/material";
import { Card } from "@mui/material";
import { Button } from "@mui/material";
import { color } from "@mui/system";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Vector from "../images/Vector.png";
import { toast, ToastContainer } from "react-toastify";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [content, setContent] = useState("");

  const onClick = () => {
    console.log(name);
    console.log(email);
    console.log(phone);
    console.log(content);
    toast.success("Message Sent.  We will get back to you soon!");

    fetch("https://api.sendgrid.com/v3/mail/send", {
      method: "POST",
      body: `{"personalizations": [{"to": [{"email": "myservicesdir@gmail.com"}]}],"from": {"email": "myservicesdir@gmail.com"},"subject": "Hello, World!","content": [{"type": "text/plain", "value": "<p>${name} at ${email} and ${phone}</p><p>${content}</p>"}]}`,
      headers: {
        Authorization:
          "Bearer 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",
        "Content-Type": "application/json",
      },

      /*
    fetch("https://api.sendgrid.com/v3/mail/send", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
        */
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs margin={2}>
            {" "}
            <Card
              elevation={5}
              sx={{
                backgroundColor: "#ECECEC",
                margin: 2,
              }}
            >
              <CardContent>
                <div className="formWord">
                  <h1 style={{ fontSize: "3rem" }}>Contact Us</h1>

                  <p style={{ fontSize: "18px" }}>
                    If you have comments, questions, suggestions, need to add a
                    service, or want to report an issue, please reach out to us.
                  </p>
                  <p style={{ fontSize: "18px" }}>We are here to help!</p>
                  <br />
                  <p style={{ fontWeight: "bold" }}>
                    Email: admin@myservicedirectory.com
                  </p>
                  <p style={{ fontWeight: "bold" }}>Phone: 307-296-7576</p>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs margin={2}>
            <Card
              elevation={5}
              sx={{
                minWidth: "250px",
                backgroundColor: "#ECECEC",
                margin: 8,
                spacing: 4,
              }}
            >
              <h1 style={{ fontSize: "2rem" }}>Who are we?</h1>
              <CardContent>
                <i>
                  <p style={{ fontSize: "1rem" }}>
                    We are a brother/sister team that live in Basin. Our family
                    has lived in the Big Horn Basin for almost 70 years. As
                    homeowners, community members, and real estate investors in
                    the Basin, we have often struggled to find professionals
                    that service our area and we believe that this online
                    Directory will benefit both consumers and providers. We want
                    this to be the first place you think of when you are trying
                    to find services in the Basin. Nicole has had this idea for
                    years and with Ryan's programming knowledge we have been
                    able to build this site from the ground-up. Multiple
                    brainstorms and numerous hours were spent to make the user
                    experience easy and enjoyable. This is only the beginning
                    and we are always open to suggestions. Don't hesitate to
                    reach out to us with ideas on how we can make this site
                    better!
                  </p>
                </i>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default ContactUs;
